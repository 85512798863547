<template>
  <div class="flex flex-col justify-between h-full md:shadow-pink md:rounded-xl">
    <section class="forms-basic">
      <!--terms 約定條款-->
      <section class="px-2 py-4 text-base text-gray-450 leading-8 md:p-8 lg:px-16 md:bg-gray-10">
        <h3 class="text-2xl mb-6 text-pink-500">歡迎您加入成為美華泰 – 揪美美平台會員</h3>
        <!-- <div>
              依據本條款提供線上消費服務，凡於本網站各網路店家進行線上消費的使用者，皆請詳細閱讀下列條款，當您按下「我已閱讀全文並同意遵守以上規定」鍵，或在本網站進行線上消費時，即視為已知悉、瞭解並同意接受本約定條款。如果您不同意本約定書的內容，或者您所屬的國家或地域排除本約定書內容之全部或一部份時，您應立即停止使用本網站提供之服務。
              <br />
              若您未滿20歲，除應符合上述規定外，並請由您的家長（或監護人）閱讀、瞭解並同意本約定書之所有內容及其後之修改變更後，方得使用或繼續使用本網站所提供之服務。當您使用或繼續使用本網站時，即表示您的家長（或監護人）已閱讀、瞭解並同意接受本約定書之所有內容及其後之修改變更。
              除本條款外，使用者並應遵守本網站為線上消費或交易所擬定之其他相關辦法及說明。
            </div>
            <br /> -->
        <h3 class="text-xl mb-6 text-pink-500">購物及服務條款</h3>

        <!-- ※ 為了能使用本服務，您需同意以下事項：<br />
            <br /> -->
        <article>
          <h4 class="mb-3">一、您的註冊或登錄資料義務</h4>
          <ul>
            <li>1、為了完成交易，包括個人資料提供、付款、交貨等，所有在本網站進行線上消費的使用者，都必須擔保所留存的資料與事實相符，如果事後有變更，應該即時通知網站管理者。</li>
            <li>2、本網站得限制同一自然人或法人所得申請註冊之會員帳號數量；對於註冊成為會員之申請，本網並保留是否同意其註冊之權利。</li>
            <li>3、請您對於個人資料、付款資料（包含信用卡資料）及會員密碼等，均應妥善保管以避免外洩。所有使用其帳號和密碼進入本系統後之行為，均視為該帳號及密碼持有人之行為。</li>
            <li>4、註冊完成後，本站將於10個工作天（不含假日）內完成資格審核，審核通過即可進行線上消費，若您提供任何錯誤或不實的資料，本網站有權暫停或終止您的帳號，並拒絕您使用本網站服務。</li>
          </ul>
        </article>
        <article>
          <br />
          <h4 class="mb-3">二、回饋機制</h4>
          1、當您在揪美美註冊完成時，您就可以加入回饋計畫，透過合格消費累積回饋金額，現金回饋根據每季度合格消費的總額來計算，不包括任何手續費、稅金、運費，且以使用優惠券或折扣碼後的實際金額為準，回饋方案依網站公告為準，您可以進入揪美美後台，查看當前的消費總額及回饋金額。
        </article>
        <br />
        <article>
          <h4 class="mb-3">三、線上消費</h4>
          <ul>
            <li>
              1、您透過本網站所購買或取得之任何產品或資訊，係由店家負責提供，本公司非出賣人將不負擔任何瑕疵擔保責任，相關商品或服務之品質、保固及售後服務，由提供各該商品或服務之店家負責，本網站僅係提供交易平台、金流付款機制。
            </li>
            <li>
              2、您與《揪美美團購平台》之店家發生消費糾紛時，應立即通知網站客服人員，我們將會暫時保留該筆具爭議性貨款，直到消費者問題已獲店家合理、圓滿解決，並承諾全力協助您解決關於因為線上消費所產生之疑問或爭議。
            </li>
            <li>3、本網站對於商家各網路商店中所刊登資訊，不負責實質審查，因此，對於刊登內容所涉及之正確性、智慧財產權歸屬，或其合法性與正當性，不負任何明示或默示之保證責任。</li>
            <li>
              4、您一旦在本網站進行線上消費，即表示願意購買該商品或服務並願遵守交易規則。您的資料(如地址、電話)如有變更時，應立即上線修正其所留存之資料，且不得以資料不符為理由，否認其訂購行為或拒絕付款。
            </li>
            <li>5、所有在本網站所進行的線上消費，您應同意以本網站所紀錄之電子交易資料為準，如有糾紛，並以該電子交易資料為認定標準。您如果發現交易資料不正確，應立即通知網站客服人員。</li>
            <li>
              6、退、換貨處理 <br />
              <div>
                <p>
                  (1)本網站的消費者都可以依照消費者保護法的規定，享有商品到貨次日起七天猶豫期的權益(提醒您 :
                  七天猶豫期非商品試用期)，若辦理退/換貨，請留意您所退回的商品必須回復原狀（須回復至商品到貨時的原始狀態）並且保持完整包裝（包括商品本體、配件、贈品、保證書、原廠包裝及所有附隨文件或資料的完整性），切勿缺漏任何配件或損毀原廠外盒。
                  提醒您，原廠外盒及原廠包裝都屬於商品的一部分，若有遺失、毀損或缺件，可能影響您退貨的權益，也可能依照損毀程度扣除為回復原狀所必要的費用。如果您所購買的商品是電腦軟體、遊戲光碟、CD、VCD、DVD、耗材等一經拆封即無法回復原狀的商品，在您還不確定是否要辦理退貨以前，請勿拆封。
                </p>
                <p>
                  (2)您如要求退換貨或解約之商品，請先與原訂購之店家聯繫，相關商品除應保持產品、附件、內外包裝及所有附隨文件或資料之完整性外，並應將商品及發票退回各網路商店所在地點，否則店家可能拒絕受理。
                </p>
                <p>
                  (3)擬辦理退換貨或解約之商品經店家受理後，店家會逕行退換貨給您，如為解約店家會通知本公司。當本公司收到店家得辦理貨款退還通知時，會儘速辦理相關作業，惟退款之匯款手續費，需由您自行負擔。
                </p>
              </div>
            </li>
            <li><span class="text-red-400">7、本網站銷售之商品僅供會員於購物社團販售，禁止於公開線上電子商務平台銷售，違者本網站有權暫停或終止您的帳號。</span></li>
          </ul>
        </article>
        <br />
        <article>
          <div>
            揪美美會員即日起升級為COCORO MEMBERS <br />
            ※ 為了能使用本服務，您需同意以下事項：
          </div>
        </article>
        <article>
          <h4 class="mb-3">申請人同意</h4>
          <h4 class="mb-3">一、認知與接受條款</h4>
          <div>
            COCORO MEMBERS會員服務（以下簡稱「會員服務」），係由「可購樂股份有限公司」(以下稱本公司)所建置提供。當您完成COCORO
            MEMBERS會員註冊手續、或開始使用本服務時，即表示您已閱讀、瞭解並同意接受本服務條款之所有內容。這些約定條款訂立的目的，是為了保護會員服務的提供者以及所有使用者的利益，並構成使用者與會員服務提供者之間的契約，本約定書之內容之修改或變更，本公司得以於COCORO
            MEMBERS
            官方網站及APP服務公告，建議您隨時注意該等修改或變更。若您於前開修改或變更後繼續使用本服務時，視為您已閱讀、瞭解並同意接受該等修改或變更。若您為未滿二十歲之未成年人，應於您的家長（或監護人）閱讀、瞭解並同意本約定書之所有內容及其後修改變更後，方得註冊為會員、使用或繼續使用本服務。當您使用或繼續使用
            COCORO MEMBERS服務時，即視為您的家長（或監護人）已閱讀、瞭解並同意接受本約定書之所有內容及其後修改變更。
          </div>
        </article>
        <br />
        <article>
          <h4 class="mb-3">二、您的註冊義務</h4>
          為了能使用本服務，您同意以下事項：
          <ul>
            <li>1、依本服務註冊時應填寫之欄位提供您本人正確、最新的資料，且無利用偽造或變造之資料作為重複註冊登錄等情事。</li>
            <li>2、維護並即時更新您個人資料之正確性，以獲取最佳之服務。</li>
            <li>3、若您提供任何錯誤或不實的資料、或未按指示提供資料、或欠缺必要之資料時，COCORO MEMBERS有權不經事先通知，逕行暫停或終止您的帳號，並拒絕您使用本服務之全部或一部份。</li>
          </ul>
        </article>
        <br />
        <article>
          <h4 class="mb-3">三、會員服務</h4>
          <ul>
            <li>
              1、本公司完成並確認會員申請後，將依本系統當時所建置的服務、項目、內容、狀態及功能，對會員提供服務；本公司保留隨時新增、減少或變更各該服務、項目、內容及功能之全部或一部之權利，且不另行個別通知。
            </li>
            <li>
              2、部份服務或項目可能係由本公司之關係企業或合作夥伴所建置或提供、或需由會員進行個別申請或登錄程序、或需由會員付費使用，均依當時及隨後所修改的各該服務及項目之使用說明及相關頁面而定。
            </li>
            <li>3、本公司保留隨時變更免費服務為收費服務、以及變更收費標準之權利，變更後之內容，除公佈於各該相關網頁或APP外，將不另行個別通知。</li>
            <li>4、部分會員服務可能另訂有相關使用規範或約定，會員應同時遵守各該服務或項目之使用規範及相關約定。</li>
            <li>5、本公司會主動、或依會員之訂閱，寄送定時或不定時的各式電子報給會員，若會員確定不想要收到會員電子報，請至COCORO MEMBERS官方網站公布服務管道聯絡。</li>
          </ul>
        </article>
        <br />
        <article>
          <h4 class="mb-3">四、個人資料保護</h4>
          <ul>
            <li>
              1. 關於您所登錄或留存之個人資料及其他特定資料（例如交易資料），悉依 COCORO MEMBERS「隱私權政策」受到保護與規範。 COCORO
              MEMBERS為提供您更完善與多元之服務與商品，依所提供之服務需要，蒐集您的基本資料與帳務資料，並依您提供資料時所授權使用的目的進行服務提供或商品銷售。您同意本公司及本公司相關業務合作夥伴(含相關履行輔助人及代理人)相關企業，在為提供本服務行銷、市場分析、統計或研究、或為提供會員個人化服務或加值服務之目的範圍內，得記錄、保存及利用您在本服務所留存或產生的資料及記錄，或提供您所需的行銷活動及相關優惠訊息給您。
              COCORO MEMBERS 將持續使用您提供的個人資料直至您提出停止使用或 COCORO MEMBERS終止提供服務日為止。您的個人資料使用區域僅限於台灣地區內使用。
            </li>
            <li>
              2.
              除基於法律之規定、或受司法機關與其他有權機關基於法定程序之要求、在緊急情況下為維護其他會員或第三人之合法權益、為維護會員服務系統的正常運作、委任第三人處理服務提供相關事務或您與本服務往來之契約或電子文件另有約定，而須揭露您的個人資料予第三人外，將不會向任何其他第三人揭露或供其使用。
              COCORO MEMBERS 依據您提出個資確認的身分證明文件申請，於三十天內提供本公司所保有您的個人資料檔案，或答覆查詢，或提供閱覽，或製給複製本。
            </li>
            <li>
              3.
              若您委託他人代為申請者，請另外提出個資確認的身分證明，以備查核。若您申請查詢或請求閱覽個人資料或製給複製本，本公司得酌收相當費用。收費將依本公司所規定的標準辦理。若本公司未能於三十天內處理您的申請，將會將原因以書面或電子文件方式告知與您知悉。您亦可在本公司提供的「官方網站及APP」登入您的帳號及密碼，線上即時查閱您的個人資料檔案。若您委託他人代為登入者，您將負完全的責任。如果您的個人資料有變更，您可自行於
              COCORO MEMBERS 官方網站及APP進行更正您的個人資料。如果您不願意將您的資料提供予COCORO MEMBERS、COCORO
              MEMBERS聯盟企業及各合作特約商繼續使用，您也可以發信至本會員客服中心，我們將停止繼續使用您的資料或刪除您的資料(會員資料一旦刪除即視同放棄所有點數回饋亦不得回溯)。
              您得自由選擇提供個人資料之程度，惟若提供之資料不足或有誤時，本服務則可能將無法提供完整之服務。
            </li>
            <li>
              4.
              在下列的情況下，本公司有可能會查看或提供會員的個人資料或相關電信資料給相關政府機關、或主張其權利受侵害並提出適當證明之第三人：依法令規定、或依司法機關或其他相關政府機關的命令；會員涉及違反法令、侵害第三人權益、或違反本使用條款或各該使用規範或約定；為保護會員服務系統之安全或經營者之合法權益；為保護其他使用者或其他第三人的合法權益；為維護本系統的正常運作。當您使用COCORO
              MEMBERS提供之相關服務，即表示您同意了此隱私權政策，完整之隱私權政策請參照「COCORO MEMBERS個資告知條款」所公告之事項
            </li>
            <li>
              5. 會員帳號、密碼及安全帳號不可變更需確認與現行作法不同
              完成本服務的註冊登記程序之後，您將取得一個特定之密碼及會員帳號，您務必維持密碼及帳號之機密安全，任何依照系統規定方法輸入會員帳號及密碼與登入資料一致時，無論是否由本人親自輸入，均將視為會員本人所使用。利用該密碼及帳號所進行的一切行動，您須負完全的責任。您並同意以下事項：
              會員可以自行選擇使用者名稱和密碼，但會員有妥善自行保管和保密的義務，不得透漏或提供予第三人使用，對於使用特定使用者名稱和密碼使用會員服務之行為、以及登入系統後之所有行為，均應由持有該使用者名稱和密碼之會員負責。
              您的帳號、密碼及會員權益均僅供您個人使用及享有，不得轉借、轉讓他人或與他人合用。 如您的帳號及密碼遭盜用、不當使用或其他COCORO MEMBERS無法辯識是否為本人親自使用之情況時， COCORO
              MEMBERS對此所致之損害，概不負責。
              會員如果發現或懷疑其使用者名稱和密碼被第三人冒用或不當使用，會員應立即通知本公司，以利本公司及時採取適當的因應措施；但上述因應措施不得解釋為本公司因此而明示或默示對會員負有任何形式之賠償或補償義務。
            </li>
            <li>
              6. 兒童及青少年之保護
              為確保兒童及青少年使用網路的安全，如網頁內容標示為限制級時，家長（或監護人）應確認不使未滿十八歲之兒童及青少年為瀏覽；如非列為限制級者，仍宜視其內容，由父母、監護人或其他實際照顧兒童及青少年之人輔導瀏覽。
            </li>
            <li>
              7. 使用者的守法義務及承諾
              您承諾絕不為任何非法目的或以任何非法方式使用本服務，並承諾遵守本地相關法規及一切使用網際網路之國際慣例。您若係本地以外之使用者，並同意遵守所屬國家或地域之法令。您同意並保證不得利用本服務從事侵害他人權益或違法之行為，包括但不限於：
              公布或傳送任何誹謗、侮辱、具威脅性、攻擊性、不雅、猥褻、不實、違反公共秩序或善良風俗或其他不法之文字、圖片或任何形式的檔案。 侵害或毀損COCORO
              MEMBERS或他人名譽、隱私權、營業秘密、商標權、著作權、專利權、其他智慧財產權及其他權利。 違反依法律或契約所應負之保密義務。 冒用他人名義使用本服務。 傳輸或散佈電腦病毒。 從事未經COCORO
              MEMBERS事前授權的商業行為。 刊載、傳輸、發送垃圾郵件、連鎖信、違法或未經COCORO MEMBERS許可之多層次傳銷訊息及廣告等；或儲存任何侵害他人智慧財產權或違反法令之資料。
              對本服務其他用戶或第三人產生困擾、不悅或違反一般網路禮節致生反感之行為。 其他不符本服務所提供的使用目的之行為或COCORO MEMBERS有正當理由認為不適當之行為。
            </li>
            <li>8. COCORO MEMBERS得依實際執行情形，增加、修改或終止相關活動，並選擇最適方式告知會員。</li>
            <li>
              9. 於發生下列情形之一時，COCORO MEMBERS有權可以停止、中斷提供本服務： COCORO MEMBERS 網站電子通信設備進行必要之保養及施工時。 發生突發性之電子通信設備故障時。 COCORO MEMBERS
              網站申請之電子通信服務被停止，無法提供服務時。 由於天災等不可抗力之因素或其他不可歸責於COCORO MEMBERS致使 COCORO MEMBERS服務無法提供服務時。
            </li>
            <li>10. 以電子文件為表示方法 您同意於使用本服務時一切交易之意思表示皆以電子文件作為表示方法，倘您於各項服務與功能等頁面點選同意或確認等功能鍵時，即視為您正式之意思表示。</li>
            <li>
              11. 智慧財產權的保護 COCORO MEMBERS 所使用之軟體或程式、服務上所有內容，包括但不限於著作、圖片、檔案、資訊、資料、服務架構、服務畫面的安排、網頁設計，均由 COCORO
              MEMBERS或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。任何人不得逕自使用、修改、重製、公開播送、改作、散佈、發行、公開發表、進行還原工程、解編或反向組譯。若您欲引用或轉載前述軟體、程式或服務內容，必須依法取得
              COCORO MEMBERS或其他權利人的事前書面同意。尊重智慧財產權是您應盡的義務，如有違反，您應對 COCORO MEMBERS負損害賠償責任（包括但不限於訴訟費用及律師費用等）。
              在尊重他人智慧財產權之原則下，會員同意在使用COCORO MEMBERS之服務時，不作侵害他人智慧財產權之行為。 若會員有涉及侵權之情事，COCORO
              MEMBERS可暫停全部或部份之服務，或逕自以取消會員帳號之方式處理。
              若有發現智慧財產權遭侵害之情事，請將所遭侵權之情形及聯絡方式，並附具真實陳述及擁有合法智慧財產權之聲明向本公司反應，若有任何疑問請來信至cocoro-service@sharp.com.tw
            </li>
            <li>
              12. 您對COCORO MEMBERS之授權 對於會員所登錄、留存之個人資料與使用紀錄，會員同意COCORO
              MEMBERS及本公司與相關企業於提供會員服務時，得於取得您同意之範圍內蒐集、處理、保存、傳遞及使用該等資料，及提供使用者其他資訊或服務、或作成會員統計資料、或進行關於網路行為之調查或研究，或為任何之合法使用。
              若您無合法權利得授權他人使用、修改、重製、公開播送、改作、散佈、發行、公開發表某資料，並將前述權利轉授權第三人，請勿擅自將該資料上載、傳送、輸入或提供至 COCORO MEMBERS。您並應保證COCORO
              MEMBERS及本公司與相關企業使用、修改、重製、公開播送、改作、散佈、發行、公開發表、轉授權該等資料，不致侵害任何第三人之智慧財產權，否則應對 COCORO
              MEMBERS負損害賠償責任（包括但不限於訴訟費用及律師費用等）。
              會員服務所提供的所有程式設計、介面、URL、商標或標識、電腦程式、資料庫等，其商標、著作權、其他智慧財產權及資料所有權等，均屬於本公司或授權本公司使用之合法權利人所有。
              會員同意授權本公司與相關企業得儲存、刊載於服務或項目上，並得經由服務頁面，以本公司所認為適當之方式，包括為配合不同軟體或硬體設備所製作或轉換之各種不同版本或格式，例如適合於網路線上閱讀之版本、以及可供下載於各種不同電腦或智慧型手機設備(包括智慧型手機行動電話、具有IOS或ANDROID任何平板載具、PDA以及將來市場上所開發之類似設備)之不同版本或格式等，提供予特定或不特定之人於線上瀏覽、查詢、檢索、離線閱讀或接收。會員並同意授權本公司得自行挑選會員已上傳、刊載及儲存於各項會員服務內之著作及資料，單獨、彙整、或與其他會員之著作及資料集結後發行電子報或類似電子訊息，包括但不限於使用於為配合本公司及各項會員服務之行銷或推廣目的所發送之電子報及相關訊息。
            </li>
            <li>
              13. 特別授權事項 因使用本服務所提供之網路交易或活動，可能須透過合作之宅配或貨運業者始能完成貨品(或贈品等 )之配送或取回，因此，您同意並授權COCORO
              MEMBERS得視該次網路交易或活動之需求及目的，將由您所提供且為配送所必要之個人資料 (如收件人姓名、配送地址、連絡電話等)，提供予合作之宅配或貨運業者，以利完成該次貨品
              (或贈品等)之配送、取回。其餘有關您個人資料使用或處理之規定，煩請參閱 「個資使用條款」。
            </li>
            <li>
              14. 拒絕或終止您的使用 您同意COCORO
              MEMBERS得基於維護交易安全之考量，因任何理由，包含但不限於缺乏使用，或違反本服務條款的明文規定及精神，終止您的密碼、帳號（或其任何部分）或本服務之使用，並將本服務內任何「會員內容」加以移除並刪除，亦得已通知之情形下，隨時終止本服務或任何部分。此外，您同意若本服務之使用被終止，COCORO
              MEMBERS對您或任何第三人均不承擔責任。
            </li>
            <li>
              15. 本公司確保 COCORO MEMBERS服務電腦系統具有一般可合理期待之安全性，但經由本服務鏈結之服務或網頁內容由各該服務或網頁廠商提供，本公司不保證其內容或所有資訊之正確性、可信度或即時性。
            </li>
            <li>
              16. 準據法與解釋原則
              本約定書之解釋與適用，以及與本約定書有關的爭議，均應依照本地法律予以處理，並採取對於消費者有利之解釋，並建議以台灣台北地方法院應該在公司登記所在地的所屬法院為第一審管轄法院。
            </li>
          </ul>
        </article>
        <!-- <article>
              <h4 class="mb-3">四、系統安全</h4>
              <div>
                <p>
                  1、本網站可能包含許多連結，這些被連結網站或網頁上的所有資訊，都是由被連結網站所提供，本網站不擔保其內容之正確性、可信度或即時性。
                </p>
                <p>2、本系統不擔保使用者所下載的資料將被正常顯示、亦不擔保資料傳輸的正確性；如果使用者發現本系統有錯誤或疏失，請立即通知本網站客服人員。</p>
              </div>
              <article>
                <h4 class="mb-3">四、系統中斷或故障</h4>
                <p>1、本網站系統會定期備份資料，但是除非本系統有故意或重大過失，您應同意本系統不用對失誤刪除的資料或備份儲存失敗的資料負責。</p>
                <p>
                  2、本網站服務有時可能會出現離斷線或故障等現象，或許將造成您使用上的不便、資料喪失、錯誤、遭人篡改或其他經濟上損失等情形。您於使用本服務時宜自行採取防護措施。本公司對於您因使用（或無法使用）本服務而造成的損害，恕不負任何賠償責任。
                </p>
              </article>
            </article>
            <article>
              <h4 class="mb-3">五、約定條款修改公布事宜</h4>
              <p>本公司保留隨時修改本條款之權利，修改後的條款將公布在本網站首頁上，不另作個別通知，您如繼續使用本網站之服務，應視同同意遵守修改後之約定條款。</p>
            </article>
            <article>
              <h4 class="mb-3">六、準據法及管轄法院</h4>
              <p>1、您應同意，本條款及所有在本網站所進行的線上消費或交易，均以中華民國法令為準據法。</p>
              <p>2、因本條款所發生之糾紛，以臺灣士林地方法院為第一審管轄法院。</p>
            </article> -->
      </section>
      <!--/terms 約定條款-->
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style scoped>
/* .cocoro-member {
        max-width: 800px;
    } */
h2,
h3 {
  font-weight: bold;
  color: #ff8d9d;
  /* color: #d30180 */
}

.text-red {
  color: red;
}
</style>