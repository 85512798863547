<template>
  <AuthContainer @onBack="handleBack" title="登入">
    <!--pc login 登入頁面內容-->
    <article class="hidden md:block white-panel md:h-auto">
      <div v-if="$route.query.desc == 1">
        <p class="text-pink-salmon text-center">請輸入您的可購樂帳號及密碼</p>
      </div>
      <div class="flex max-w-930 mx-auto md:border-4 rounded-2xl">
        <figure class="login-vision hidden md:block mb-0"></figure>
        <div class="flex flex-col justify-between mx-auto h-inherit w-full md:flex-1">
          <section class="forms-basic login-con md:mt-8 md:mx-8">
            <figure class="login-logo md:hidden"></figure>
            <h2 class="hidden md:block text-3xl font-bold text-center mb-2">登入</h2>
            <a-form-model ref="loginForm">
              <div class="">
                <p class="item-title"><span>帳號（手機號碼）</span></p>
                <a-form-model-item>
                  <a-input type="text" data-cy-pc="mobile" placeholder="請輸入你的帳號（手機號碼）" v-model="form.mobile" autocomplete />
                </a-form-model-item>
              </div>
              <div class="">
                <p class="item-title"><span>密碼</span></p>
                <a-form-model-item>
                  <a-input type="password" data-cy-pc="password" placeholder="請輸入你的密碼" v-model="form.password" autocomplete />
                </a-form-model-item>
              </div>
              <div class="p-3 flex flex-row justify-between">
                <a-checkbox name="remember-pc" @change="onRememberMe">
                  記住我
                </a-checkbox>
                <router-link class="text-pink-salmon focus-within:" to="/reset">忘記密碼</router-link>
              </div>
            </a-form-model>
          </section>
          <section class="md:mb-8 md:mx-8">
            <button @click="handleLogin" class="btn-forms btn-block mb-4">登入</button>
            <router-link :to="{ name: 'terms' }" class="btn-link btn-block">註冊</router-link>
          </section>
        </div>
      </div>
    </article>

    <!--mobile login 登入頁面內容-->
    <article class="block md:hidden white-panel">
      <div class="flex flex-col justify-between container-wrapper no-padding h-inherit">
        <section class="forms-basic login-con">
          <figure class="login-logo"></figure>
          <div v-if="$route.query.desc == 1">
            <p class="text-pink-salmon text-center">請輸入您的可購樂帳號及密碼</p>
          </div>
          <ul class="basic-list">
            <li>
              <p class="item-title"><span>帳號（手機號碼）</span></p>
              <p class="item-con item-id">
                <input type="text" data-cy="mobile" v-model="form.mobile" placeholder="請輸入你的帳號" />
                <i>user id</i>
              </p>
            </li>
            <li>
              <p class="item-title"><span>密碼</span></p>
              <p class="item-con item-pass">
                <input type="password" data-cy="password" v-model="form.password" placeholder="請輸入你的密碼" />
                <i>pass</i>
              </p>
            </li>
            <li class="p-3 flex flex-row justify-between">
              <a-checkbox class="w-1/3" name="remember" @change="onRememberMe">
                記住我
              </a-checkbox>
              <router-link class="text-pink-salmon w-1/3 text-center" to="/terms">註冊</router-link>
              <router-link class="text-pink-salmon w-1/3 text-right" to="/reset">忘記密碼</router-link>
            </li>
          </ul>
        </section>
        <section>
          <button data-cy="login" @click="handleLogin" class="btn-forms btn-block">登入</button>
        </section>
      </div>
    </article>

    <a-modal width="800px" bodyStyle="margin-top: 50px; margin-bottom: 50px" :visible="cocoroMemberVisible" :footer="null" :closable="false" :maskClosable="true" centered destroyOnClose>
      <VerifyCocoroMember />
      <div class="flex mt-5">
        <button class="btn-forms btn-light w-1/2" @click="cocoroMemberVisible = false">取消</button>
        <button class="btn-forms w-1/2" @click="handleVerifyCocoroMember">同意</button>
      </div>
    </a-modal>
  </AuthContainer>
</template>

<script>
import AuthContainer from '@/components/auth/AuthContainer'
import VerifyCocoroMember from '@/components/VerifyCocoroMember'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    AuthContainer,
    VerifyCocoroMember
  },
  data: function() {
    return {
      form: {
        mobile: localStorage.getItem('remember-me') || '',
        password: ''
      },
      rememberMe: false,
      cocoroMemberVisible: false,
    }
  },
  methods: {
    ...mapActions(['login', 'setRememberMe', 'setVerifyCocoroMember']),
    handleBack() {
      this.$router.push('/')
    },
    async handleLogin() {
      if (!this.form.mobile || !this.form.password) {
        window.alert('請輸入帳號或密碼')
        return
      }

      // 記住我
      await this.setRememberMe(this.rememberMe)

      const formData = new FormData()
      formData.append('mobile', this.form.mobile)
      formData.append('password', this.form.password)
      this.login(formData)
    },
    onRememberMe(e) {
      this.rememberMe = e.target.checked
    },
    async handleVerifyCocoroMember() {
      if (!this.form.mobile || !this.form.password) {
        window.alert('請輸入帳號或密碼')
        return
      }

      // 記住我
      await this.setRememberMe(this.rememberMe)

      const formData = new FormData()
      formData.append('mobile', this.form.mobile)
      formData.append('password', this.form.password)
      formData.append('importData', true)
      this.login(formData)
    }
  },
  computed: {
    ...mapGetters(['isMobileExists', 'verifyCocoroMember'])
  },
  watch: {
    verifyCocoroMember: function() {
      if(this.verifyCocoroMember == true) {
        this.cocoroMemberVisible = true
        this.setVerifyCocoroMember(false)
      }
    }
  },
  mounted() {
    if (this.$route.query.mobile) {
      this.form.mobile = this.$route.query.mobile
    }
  }
}
</script>
